import styled from '@emotion/styled';
import { Image, InternalLink, NoStyleButton } from '@pafcloud/base-components';
import { Breakpoint, Color, Font, FontTextSize } from '@pafcloud/style';

const SEARCH_BREAKPOINT = Breakpoint.LaptopOrLarger;

export const ResultLink = styled(InternalLink)({
  display: 'grid',
  gridTemplateColumns: 'minmax(80px, 1fr) 2fr',
  alignItems: 'center',
  columnGap: 8,
  outline: 'transparent',

  // Add extra space to the right when delete button is visible
  'button + &': {
    paddingRight: 28,
  },

  [SEARCH_BREAKPOINT]: {
    gridTemplateColumns: '1fr',
  },
});

export const ResultImage = styled(Image)({
  position: 'relative',
  display: 'block', // prevent unwanted space below image
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: Color.CornerRadius.Base,

  'a:hover &': {
    zIndex: 2,
    transform: 'scale(1.025)',
    boxShadow: Color.Elevation.Level2,
  },
});

export const ResultImageContainer = styled.figure({
  position: 'relative',
  margin: 0,
  padding: 0,
  aspectRatio: '16/9',
  display: 'block',
  outline: 'transparent',
  marginBottom: 8,

  // iOS 14 does not support aspect-ratio
  '@supports not (aspect-ratio: 1/1)': {
    '::before': {
      content: '""',
      float: 'left',
      height: 0,
      paddingTop: '56.25%',
    },
    '::after': {
      content: '""',
      display: 'table',
      clear: 'both',
    },
  },

  img: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
    borderRadius: Color.CornerRadius.Base,
  },

  'a:focus-visible &::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 7,
    boxShadow: `inset 0 0 0 4px ${Color.Primitive.Accent}`,
  },
});

export const ResultList = styled.ul({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 360px), 1fr))',
  gap: 16,
  margin: 0,
  padding: 0,
  listStyle: 'none',

  [SEARCH_BREAKPOINT]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(max(280px, 18%), 1fr))',

    gap: 24,
  },
});

export const ResultListItem = styled.li({
  position: 'relative',

  mark: {
    display: 'inline-block',
    background: 'transparent',
    color: 'inherit',
    textDecoration: 'underline',
    textDecorationThickness: 2,
  },
});

export const ResultDeleteButton = styled(NoStyleButton)({
  position: 'absolute',
  top: 16,
  right: 0,
  padding: '4px',
  color: Color.TextColors.MutedText,
  fontSize: FontTextSize.Small,
  outline: 'none',
  cursor: 'pointer',

  svg: {
    transform: 'scale(0.8)',
  },

  '&:focus, &:hover': {
    outline: 'none',
  },

  [Breakpoint.LaptopOrLarger]: {
    display: 'none',

    'li:hover &': {
      display: 'block',
    },
  },
});

export const ResultInfo = styled.div({
  position: 'relative',
  minWidth: 0,
});

export const ResultTitle = styled.strong({
  display: 'block',
  maxWidth: '100%',
  alignSelf: 'end',
  fontSize: FontTextSize.Normal,
  fontFamily: Font.Heading,
  color: Color.TextColors.BodyText,
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  whiteSpace: 'nowrap',
});

export const ResultSupplier = styled.span({
  display: 'inline-block',
  maxWidth: '100%',
  fontSize: FontTextSize.Small,
  fontFamily: Font.Body,
  color: Color.TextColors.MutedText,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const ResultWrapper = styled.div({
  overflowX: 'hidden',
  paddingLeft: 32,
  paddingRight: 32,
});

export const ResultHeader = styled.h3({
  fontSize: FontTextSize.Normal,
  color: Color.TextColors.BodyText,
});

export const NoResultMessage = styled.p({
  color: Color.TextColors.MutedText,
});
