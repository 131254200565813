import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,
  TrackBackground: `${Color.Surface.Nested.Background}80`,
  TrackBackgroundChecked: Color.Surface.Nested.Background,

  ThumbBackground: Color.Primitive.PrimaryContrast,
  ThumbBackgroundChecked: Color.Primitive.Accent,
};
