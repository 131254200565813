import type { FC } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from '@pafcloud/i18n';
import { Icon } from '@pafcloud/base-components';
import { rememberGameHomeUrl } from '@pafcloud/react-hook-utils';
import { useRecentSearch } from './useRecentSearch';
import type { RecentSearchResultChildrenProps } from './RecentSearchResultChildren';
import {
  ResultDeleteButton,
  ResultImage,
  ResultImageContainer,
  ResultInfo,
  ResultLink,
  ResultSupplier,
  ResultTitle,
} from './search-result-styles/searchResultStyles.alternate';

export const RecentSearchResultChildren: FC<RecentSearchResultChildrenProps> = ({
  listRef,
  gameListId,
  onClickResult,
  search,
}) => {
  const { t } = useTranslation('search');
  const { asPath } = useRouter();
  const { deleteRecentSearchResult, insertRecentSearchResult } = useRecentSearch(gameListId);

  return (
    <>
      <ResultDeleteButton
        onClick={() => {
          listRef.current?.focus();
          deleteRecentSearchResult(search);
        }}
        aria-label={t('search-recent.delete', { gameTitle: search.rawTitle })}
      >
        <Icon name="trash" size="18" />
      </ResultDeleteButton>

      <ResultLink
        onClick={(event) => {
          insertRecentSearchResult(search);
          rememberGameHomeUrl(asPath);
          onClickResult?.();
          event.currentTarget.blur();
        }}
        href={search.launchUrl}
      >
        <ResultImageContainer>
          {search.thumbnail.src == null ? (
            <ResultImage
              // fallback for players with saved results in non-object format
              src={search.thumbnail}
              width={400}
              height={400}
              sizes="
            (max-width: 500px) 50vw,
            (max-width: 1336px) 20vw,
            (max-width: 1920px) 25vw, 450px"
            />
          ) : (
            <ResultImage src={search.thumbnail.src} width={search.thumbnail.width} height={search.thumbnail.height} />
          )}
        </ResultImageContainer>
        <ResultInfo>
          <ResultTitle>{search.rawTitle}</ResultTitle>
          <ResultSupplier>{search.rawSupplier}</ResultSupplier>
        </ResultInfo>
      </ResultLink>
    </>
  );
};
