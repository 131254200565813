import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { logger } from '@pafcloud/logging';
import { Icon } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { ButtonContent, HeaderIconButton, IconWrapper } from '../header-icon-button';
import { HeaderSearchOverlay } from '../../search/HeaderSearchOverlay';
import { dispatchSearchStartedEvent } from '../../search/tracking/dispatchSearchStartedEvent';
import { RecentSearchProvider } from '../../search/RecentSearchContext';
import type { HeaderSearchBar_data$key } from './__generated__/HeaderSearchBar_data.graphql';
import { useCloseOnBack } from './useCloseOnBack';

const headerSearchBarFragment = graphql`
  fragment HeaderSearchBar_data on Query {
    site {
      menu {
        search {
          gameListId
        }
      }
    }
  }
`;

type HeaderSearchBarProps = {
  className?: string;
  data: HeaderSearchBar_data$key;
};

export const HeaderSearchBar: FC<HeaderSearchBarProps> = (props) => {
  const { t } = useTranslation('search');
  const data = useFragment(headerSearchBarFragment, props.data);
  const [searchIsOpen, setSearchIsOpen] = useCloseOnBack();

  if (data.site.menu?.search.gameListId == null) {
    logger.error('GameListId not defined for header search field.');
    return null;
  }

  return (
    <>
      <HeaderIconButton
        onClick={() => {
          setSearchIsOpen(!searchIsOpen);
          dispatchSearchStartedEvent();
        }}
        aria-label={t('search-input.placeholder')}
      >
        <ButtonContent>
          <IconWrapper>
            <Icon name="search" size={24} aria-hidden />
          </IconWrapper>
        </ButtonContent>
      </HeaderIconButton>
      <RecentSearchProvider>
        <HeaderSearchOverlay
          gameListId={data.site.menu.search.gameListId}
          searchIsOpen={searchIsOpen}
          setSearchIsOpen={setSearchIsOpen}
        />
      </RecentSearchProvider>
    </>
  );
};
