import { createTransparentColor } from '../create-transparent-color';
import type { ColorDefinition } from './color-types';

const DreamsPalette = {
  Elderberry: '#141529',

  Sky: '#00C6FA',
  SkyLight: '#31D6FF',

  // Accent Colors
  Purple: '#9500e6',
  Green: '#16F87F',
  GreenLight: '#3EF894',
  Red: '#FB3640',

  // Gradients
  GradientStart: '#ff00ff',
  GradientEnd: '#00ffff',
  GradientPurpleStart: '#A700FF',
  GradientPurpleEnd: '#60008B',

  DeepBlue: '#0c0d1b',

  Black: '#02020a',
  Darker: '#101023',
  Dark: '#030518',
  Light: '#F9F0FC',
  Neutral: '#7f7c91',
  White: '#ffffff',
};

const Primitive: ColorDefinition['Primitive'] = {
  Primary: DreamsPalette.Purple,
  PrimaryTint: DreamsPalette.GradientPurpleStart,
  PrimaryContrast: DreamsPalette.White,

  Secondary: DreamsPalette.Sky,
  SecondaryTint: DreamsPalette.SkyLight,
  SecondaryContrast: DreamsPalette.White,

  Accent: DreamsPalette.Green,
  AccentTint: DreamsPalette.GreenLight,
  AccentContrast: DreamsPalette.Black,

  Gradient: `
  linear-gradient(0deg, ${DreamsPalette.GradientPurpleEnd}ee, ${DreamsPalette.GradientPurpleStart}00) border-box,
  linear-gradient(-90deg, ${DreamsPalette.GradientEnd} 1%, ${DreamsPalette.GradientEnd} 10%, ${DreamsPalette.GradientStart} 97%, ${DreamsPalette.GradientStart} 99%) border-box
  `,
  GradientTint: `
  linear-gradient(0deg, ${DreamsPalette.GradientPurpleEnd}ff 10%, ${DreamsPalette.GradientPurpleStart}00) border-box,
  linear-gradient(-90deg, ${DreamsPalette.GradientEnd} 1%, ${DreamsPalette.SkyLight} 10%, ${DreamsPalette.GradientStart} 97%, ${DreamsPalette.GradientStart} 99%) border-box
  `,
  GradientContrast: DreamsPalette.White,
};

const TextColors: ColorDefinition['TextColors'] = {
  HeadingText: DreamsPalette.White,
  BodyText: DreamsPalette.Light,
  MutedText: '#b4a5bb',
  HighlightedText: DreamsPalette.White,
  LinkText: DreamsPalette.Green,
  ErrorText: DreamsPalette.Red,
  DisabledText: DreamsPalette.Neutral,
};

const Surface: ColorDefinition['Surface'] = {
  Base: {
    Background: DreamsPalette.DeepBlue,
    Foreground: DreamsPalette.White,
    Dimmed: '#464353',
  },
  Nested: {
    Background: DreamsPalette.Black,
    Foreground: DreamsPalette.Light,
    Dimmed: createTransparentColor(DreamsPalette.Neutral, 0.5),
  },
  Disabled: {
    Background: DreamsPalette.Neutral,
    Foreground: DreamsPalette.Dark + '80',
    Dimmed: createTransparentColor(DreamsPalette.Dark, 0.15),
  },
  Floating: {
    Background: DreamsPalette.Dark,
    Foreground: DreamsPalette.White,
    Dimmed: createTransparentColor(DreamsPalette.Light, 0.15),
  },
};

const Signal: ColorDefinition['Signal'] = {
  Success: DreamsPalette.Green,
  SuccessContrast: DreamsPalette.Darker,

  Info: DreamsPalette.Green,
  InfoContrast: DreamsPalette.Darker,

  Attention: DreamsPalette.Purple,
  AttentionContrast: DreamsPalette.White,

  Danger: DreamsPalette.Red,
  DangerContrast: DreamsPalette.White,
};

const CornerRadius: ColorDefinition['CornerRadius'] = {
  Small: '4px',
  Base: '8px',
  Large: '12px',
};

const Elevation: ColorDefinition['Elevation'] = {
  Level1: '0 1px 2px 0 var(--shadow-color, rgba(0, 0, 0, 0.2))',
  Level2: `0 4px 6px 0 var(--shadow-color, ${createTransparentColor(DreamsPalette.Black, 0.2)})`,
  Level3: '0 8px 12px 0 var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: ColorDefinition['General'] = {
  Bonus: DreamsPalette.Purple,
  BonusContrast: DreamsPalette.White,
};

const Layout: ColorDefinition['Layout'] = {
  Background: DreamsPalette.Elderberry,
  BrandBorder: `linear-gradient(90deg, ${DreamsPalette.GradientStart}, ${DreamsPalette.Sky})`,
  BrowserTheme: DreamsPalette.Elderberry,
  LoadingColor: DreamsPalette.Purple,
  Overlay: DreamsPalette.Elderberry + 'E6',
  Sidebar: {
    Background: DreamsPalette.Dark,
    Foreground: DreamsPalette.White,
  },
};

const Hero: ColorDefinition['Hero'] = {
  Heading: DreamsPalette.White,
  Text: DreamsPalette.White,
  TextHighlight: DreamsPalette.Green,

  Button: {
    Solid: Primitive.Primary,
    Rest: Primitive.Gradient,
    Hover: Primitive.GradientTint,
    Active: Primitive.GradientTint,
    Text: Primitive.GradientContrast,
  },
};

export const Color: ColorDefinition = {
  TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,
};
