import { enGB } from 'date-fns/locale/en-GB';
import { et } from 'date-fns/locale/et';
import { ru } from 'date-fns/locale/ru';
import type { Locale } from 'date-fns';
import type { Localized } from '../locale';

export const LanguageCodeToLocaleMap: Localized<Locale> = {
  en: enGB,
  et: et,
  ru: ru,

  // Not used
  sv: enGB,
  fi: enGB,
  de: enGB,
  fr: enGB,
  it: enGB,
  lv: enGB,
  es: enGB,
};
