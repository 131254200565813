import { pxToRem } from '../pxToRem';
import type * as Fonts from './font-types';

const FontPalette = {
  Default: `Exo, sans-serif`,
  Brand: `Exo, sans-serif`,
  Alternate: `Exo, sans-serif`,
};

export const Font: Fonts.FontType = {
  Body: FontPalette.Default,
  Button: FontPalette.Brand,
  Heading: FontPalette.Brand,
  HeadingAlt: FontPalette.Brand,
  Primary: FontPalette.Brand,
  Tiny: FontPalette.Default,
};

export const FontHeadingSize: Fonts.FontHeadingSizeType = {
  Huge: pxToRem(48),
  Big: pxToRem(38),
  Normal: pxToRem(36),
  Small: pxToRem(30),
  Tiny: pxToRem(26),
};

export const FontTextSize: Fonts.FontTextSizeType = {
  Huge: pxToRem(22),
  Big: pxToRem(18),
  Normal: pxToRem(16),
  Small: pxToRem(14),
  Tiny: pxToRem(12),
};
