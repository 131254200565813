import type { FC } from 'react';
import type { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay/hooks';
import dynamic from 'next/dynamic';
import { useTranslation } from '@pafcloud/i18n';
import {
  Breakpoint,
  Color,
  deviceWithBackdropFilter,
  deviceWithHover,
  FontTextSize,
  LayoutProps,
} from '@pafcloud/style';
import { ButtonLink, Link, Logo } from '@pafcloud/base-components';
import { useIsLoggedIn } from '@pafcloud/contexts';
import { useFlowRouter } from '@pafcloud/flow-router';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { MessageMenu } from '../menus/message-menu';
import { HeaderColors } from '../header-colors';
import type { HeaderContentX3000_content$key } from './__generated__/HeaderContentX3000_content.graphql';
import { StyledHeader } from './shared';
import type { HeaderContentProps } from './shared';
import { HeaderMenu } from './HeaderMenu';
import { HeaderSearchBar } from './HeaderSearchBar';

const ProfileMenu = dynamic(() => import('../menus/profile-menu/ProfileMenu'), { ssr: false });

const contentFragment = graphql`
  fragment HeaderContentX3000_content on Query {
    ...HeaderMenu_content
    ...HeaderSearchBar_data
    ...ProfileMenu_data
  }
`;

const Header = styled(StyledHeader)({
  justifyContent: 'space-between',
  boxShadow: 'unset',
  height: 'unset',
  minHeight: LayoutProps.HeaderHeight,
  flexWrap: 'wrap',

  [deviceWithBackdropFilter]: {
    background: HeaderColors.BackgroundBlur,
    backdropFilter: 'blur(20px)',
  },
});

const LeftSection = styled.div({
  paddingLeft: 12,
  display: 'flex',
  alignItems: 'center',
  minHeight: LayoutProps.HeaderHeight,

  [Breakpoint.LaptopOrLarger]: {
    paddingLeft: 24,
  },
});

const scrollbar: CSSObject = {
  '::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
  '::-webkit-scrollbar-track': {
    background: 'rgba(0 0 0 / 0%)',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '0.5rem',
    background: 'rgba(255 255 255 / 0%)',
    boxShadow: 'unset',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: Color.Primitive.Primary,
    boxShadow: 'unset',
  },

  [deviceWithHover]: {
    ':hover::-webkit-scrollbar': {
      width: '0.25rem',
      height: '0.25rem',
    },
    ':hover::-webkit-scrollbar-thumb': {
      background: 'rgba(255 255 255 / 30%)',
    },
  },
};

const CategoryMenu = styled.div({
  order: 3,
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  fontSize: FontTextSize.Normal,
  overflowX: 'auto',
  overflowY: 'hidden',
  // Set fixed height  to category menu to calculate betting wrapper height easier.
  minHeight: LayoutProps.HeaderHeight,

  marginTop: 1,
  padding: '16px 32px',
  borderTop: 'solid 1px rgba(255, 255, 255, 0.1)',
  fontWeight: 'bold',
  userSelect: 'none',
  ...scrollbar,

  [Breakpoint.LaptopOrLarger]: {
    order: 'unset',
    border: 0,
    width: 'auto',
    minHeight: 'unset',

    display: 'flex',
    padding: '1rem 2rem 1rem 60px',

    '::before': {
      content: '""',
      position: 'absolute',
      left: 28,
      height: '1rem',
      background: Color.Primitive.Primary,
      width: 3,
      transform: 'skew(-20deg)',
    },
  },
});

const RightSection = styled.div({
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'end',
  marginLeft: 'auto',
  paddingRight: 12,

  [Breakpoint.LaptopOrLarger]: {
    paddingRight: 24,
  },
});

const LogoLink = styled(Link)({
  display: 'flex',
  marginLeft: -4,
  position: 'relative',
  outline: 'none',

  '::before': {
    content: '""',
    position: 'absolute',
    top: -4,
    left: -6,
    right: -6,
    bottom: -4,
    borderRadius: 4,
    transition: 'box-shadow 0.2s ease-in-out',
  },

  '&:focus-visible::before': {
    boxShadow: `0 0 1px 2px ${Color.TextColors.BodyText}`,
  },
});

const LogoSvg = styled(Logo)({
  height: 40,
});

const HeaderButtonLink = styled(ButtonLink)({
  alignSelf: 'center',
  minWidth: 92,

  [Breakpoint.LaptopOrLarger]: {
    minWidth: 128,
  },
});

export const HeaderContent: FC<HeaderContentProps> = ({ isMainMenuOpen, setMainMenuOpen, ...props }) => {
  const content = useFragment<HeaderContentX3000_content$key>(contentFragment, props.content);
  const { t } = useTranslation(['header', 'profile']);
  const { getFlowUrl } = useFlowRouter();
  const isLoggedIn = useIsLoggedIn();

  const flow = $buildEnv.site === 'x3000.com' ? 'deposit' : 'login';

  return (
    <Header>
      <LeftSection>
        <LogoLink aria-label={t('logo.aria-label')} href="/">
          <LogoSvg />
        </LogoLink>
      </LeftSection>
      <CategoryMenu>
        <HeaderMenu content={content} />
      </CategoryMenu>
      <RightSection>
        <HeaderSearchBar data={content} />
        {isLoggedIn && (
          <>
            <MessageMenu />
            <ProfileMenu data={content} />
          </>
        )}
        {!isLoggedIn && (
          <HeaderButtonLink
            href={getFlowUrl(flow)}
            aria-label={t('header-button.logged-out.aria-label')}
            size="default"
            variant="primary"
            type="button"
            data-testid="header-action"
          >
            {t('header-button.logged-out.text')}
          </HeaderButtonLink>
        )}
      </RightSection>
    </Header>
  );
};
