import type { FC } from 'react';
import FocusTrap from 'focus-trap-react';
import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Icon,
  renderIntoRootContainer,
  SearchContent,
  SearchField,
  SearchHeader,
  SearchBackgroundOverlay,
  ResetButton,
} from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { SearchResult } from './SearchResult';
import { RecentSearchResults } from './RecentSearchResults';
import { useSearch } from './useSearch';
import { dispatchSearchResultClickedEvent } from './tracking/dispatchSearchResultTrackingEvent';
import { useRecentSearch } from './useRecentSearch';

const FullScreenSearchField = styled(SearchField)({
  paddingLeft: 16,
});

type HeaderSearchOverlayProps = {
  gameListId: string;
  searchIsOpen?: boolean;
  setSearchIsOpen: (open: boolean) => void;
  className?: string;
};

export const HeaderSearchOverlay: FC<HeaderSearchOverlayProps> = ({ gameListId, searchIsOpen, setSearchIsOpen }) => {
  const { t } = useTranslation('common');
  const { search, isLoading, reset, response, value } = useSearch(gameListId);
  // This needs to be called when the overlay is opened in order to populate the recent search list
  useRecentSearch(gameListId);

  if (!searchIsOpen) {
    return null;
  }

  return renderIntoRootContainer(
    <SearchBackgroundOverlay>
      <Global styles={{ body: { overflow: 'hidden !important' } }} />
      <FocusTrap
        focusTrapOptions={{
          escapeDeactivates: () => {
            setSearchIsOpen(false);
            return false;
          },
          allowOutsideClick: true,
        }}
      >
        <SearchContent>
          <SearchHeader>
            <FullScreenSearchField autoFocus onChange={search} value={value} reset={reset} />
            <ResetButton
              aria-label={t('close')}
              onClick={() => {
                setSearchIsOpen(false);
                reset();
              }}
            >
              <Icon name="closeThinRounded" />
            </ResetButton>
          </SearchHeader>
          {value ? (
            <SearchResult
              isLoading={isLoading}
              response={response}
              gameListId={gameListId}
              onClickResult={(searchResult) => {
                dispatchSearchResultClickedEvent({
                  ...searchResult,
                  term: value,
                });
                setSearchIsOpen(false);
                reset();
              }}
            />
          ) : (
            <RecentSearchResults
              gameListId={gameListId}
              onClickResult={(searchResult) => {
                dispatchSearchResultClickedEvent({
                  ...searchResult,
                  term: '[recent searches]',
                });
              }}
            />
          )}
        </SearchContent>
      </FocusTrap>
    </SearchBackgroundOverlay>,
  );
};
