import type { FC } from 'react';
import { useRouter } from 'next/router';
import { rememberGameHomeUrl } from '@pafcloud/react-hook-utils';
import {
  ResultImage,
  ResultLink,
  ResultSupplier,
  ResultTitle,
  ResultImageContainer,
  ResultInfo,
} from './search-result-styles/searchResultStyles.alternate';
import { useRecentSearch } from './useRecentSearch';
import type { SearchResultChildrenProps } from './SearchResultChildren';

export const SearchResultChildren: FC<SearchResultChildrenProps> = ({ gameListId, onClickResult, searchResult }) => {
  const { asPath } = useRouter();
  const { insertRecentSearchResult } = useRecentSearch(gameListId);

  return (
    <ResultLink
      onClick={(event) => {
        insertRecentSearchResult(searchResult);
        rememberGameHomeUrl(asPath);
        onClickResult?.();
        event.currentTarget.blur();
      }}
      href={searchResult.launchUrl}
    >
      <ResultImageContainer>
        <ResultImage
          src={searchResult.thumbnail.src}
          width={searchResult.thumbnail.width}
          height={searchResult.thumbnail.height}
          sizes="
            (max-width: 500px) 50vw,
            (max-width: 1336px) 20vw,
            (max-width: 1920px) 25vw, 450px"
        />
      </ResultImageContainer>
      <ResultInfo>
        <ResultTitle dangerouslySetInnerHTML={{ __html: searchResult.title }} />
        <ResultSupplier dangerouslySetInnerHTML={{ __html: searchResult.supplier }} />
      </ResultInfo>
    </ResultLink>
  );
};
