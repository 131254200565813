import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useHandler } from '@pafcloud/react-hook-utils';

export const useCloseOnBack = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { back, push, replace, events, asPath } = useRouter();

  const onChangeRoute = useHandler(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const clearEventListeners = useHandler(() => {
    window.removeEventListener('popstate', onChangeRoute);
    events.off('routeChangeComplete', onChangeRoute);
  });

  const addEventListeners = useHandler(() => {
    clearEventListeners();
    // `routeChangeComplete` is not fired if search is used immediately after loading the page,
    // so we need to listen to `popstate` as well to catch that scenario.
    window.addEventListener('popstate', onChangeRoute);
    events.on('routeChangeComplete', onChangeRoute);
  });

  useEffect(() => clearEventListeners, [clearEventListeners]);

  const effectState = (open: boolean) => {
    if (open) {
      push(`${asPath}#`).then(() => replace(asPath));
      // Hide the hash immediately so it doesn't show up in the URL bar.
      history.replaceState(null, '', asPath);
      setIsOpen(true);
      addEventListeners();
    }

    if (!open) {
      clearEventListeners();
      back();
      setIsOpen(false);
    }
  };

  return [isOpen, effectState] as const;
};
